var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "halfProceDaily" },
    [
      _c("Loading", { attrs: { visible: _vm.loading } }),
      _c("div", { ref: "container", staticClass: "container" }, [
        _c("div", { staticClass: "topBg" }),
        _c(
          "div",
          {
            staticClass: "swiperNav",
            attrs: { id: "swiperNav", "bi-route": "tags" }
          },
          [
            _c("div", { staticClass: "sceneNav" }, [
              _c("div", { staticClass: "swiper-container" }, [
                _c(
                  "div",
                  { staticClass: "swiper-wrapper" },
                  _vm._l(_vm.timerList, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "swiper-slide",
                        attrs: {
                          "bi-click": "time",
                          "data-time": item.hpdTime + ":00"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            class: index === _vm.activeIndex ? "act" : "",
                            on: {
                              click: function($event) {
                                return _vm.hanldeTimerChange(item, index)
                              }
                            }
                          },
                          [
                            _vm._v(" " + _vm._s(item.hpdTime) + ":00场 "),
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.status == 0
                                      ? "已结束"
                                      : item.status == 1
                                      ? "正在进行"
                                      : "未开始"
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  }),
                  0
                ),
                _c("div", { staticClass: "swiper-button-prev" }),
                _c("div", { staticClass: "swiper-button-next" })
              ])
            ])
          ]
        ),
        _vm._m(0),
        _vm._m(1),
        _c(
          "div",
          { staticClass: "listBlock", attrs: { "bi-route": "goods" } },
          _vm._l(_vm.goodsList, function(item, index) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "listCent",
                attrs: {
                  "bi-click": "list",
                  "data-index": index,
                  "data-time": _vm.activeTimer.hpdTime + ":00",
                  "data-gid": item.id || item.goodsId
                }
              },
              [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "?r=/l/d&id=" + (item.id || item.goodsId),
                      target: "_blank"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "marketgroup",
                            rawName: "v-marketgroup",
                            value: item,
                            expression: "item"
                          }
                        ],
                        staticClass: "img"
                      },
                      [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: item.picUrl + "_310x310.jpg",
                              expression: "`${item.picUrl}_310x310.jpg`"
                            }
                          ],
                          attrs: { alt: "" }
                        })
                      ]
                    ),
                    _c("div", { staticClass: "content" }, [
                      _c(
                        "div",
                        {
                          class: [
                            "title",
                            item.shopType === 1 ? "titleIcon" : ""
                          ]
                        },
                        [_c("p", [_vm._v(_vm._s(item.name))])]
                      ),
                      _c("div", { staticClass: "coupon" }, [
                        item.couponAmount
                          ? _c(
                              "span",
                              {
                                class: [
                                  "quan",
                                  _vm.activeTimer.status == 2 ? "quan1" : ""
                                ]
                              },
                              [
                                _c("i", [
                                  _vm._v(_vm._s(item.couponAmount) + "元券")
                                ])
                              ]
                            )
                          : _vm._e(),
                        item.activityType === 2
                          ? _c("p", { staticClass: "tag" }, [_vm._v("聚划算")])
                          : _vm._e()
                      ]),
                      _c(
                        "p",
                        {
                          class: [
                            "des",
                            _vm.activeTimer.status == 2 ? "des1" : ""
                          ]
                        },
                        [
                          item.yijuhua
                            ? _c("span", [_vm._v(_vm._s(item.yijuhua))])
                            : _vm._e()
                        ]
                      ),
                      _c("div", { staticClass: "num" }, [
                        _vm._v(" 已抢"),
                        _c("i", [
                          _vm._v(_vm._s(_vm._f("salesNum")(item.todaySellNum)))
                        ]),
                        _vm._v("件 ")
                      ]),
                      _c("div", { staticClass: "price" }, [
                        _c("p", [
                          _vm._v("￥ " + _vm._s(_vm._f("salesNum")(item.price)))
                        ])
                      ]),
                      _vm.activeTimer.status == 2
                        ? _c(
                            "button",
                            {
                              staticClass: "noStart",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                }
                              }
                            },
                            [_vm._v(" 未开始 ")]
                          )
                        : _c("button", { staticClass: "qiang" }, [
                            _vm._v("马上抢")
                          ])
                    ])
                  ]
                )
              ]
            )
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logoBlock" }, [
      _c("img", {
        attrs: { src: require("./images/every_log.png"), alt: "", height: "52" }
      }),
      _c("p", [_vm._v("限时限量 / 买一送一 / 下单立减")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spBlock" }, [
      _c("i"),
      _c("i", { staticClass: "right" }),
      _c("div", { staticClass: "spBg" }, [
        _c("img", {
          attrs: {
            src: require("./images/typelogo.png"),
            alt: "",
            height: "21"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }