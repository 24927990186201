<template>
  <div class="halfProceDaily">
     <Loading :visible="loading" />
    <div class="container" ref="container">
      <div class="topBg"></div>
      <div class="swiperNav" id="swiperNav" bi-route="tags">
        <div class="sceneNav">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in timerList"
                :key="index"
                bi-click="time"
                :data-time="`${item.hpdTime}:00`"
              >
                <div
                  :class="index === activeIndex ? 'act' : ''"
                  @click="hanldeTimerChange(item, index)"
                >
                  {{ item.hpdTime }}:00场
                  <p>
                    {{
                      item.status == 0
                        ? "已结束"
                        : item.status == 1
                        ? "正在进行"
                        : "未开始"
                    }}
                  </p>
                </div>
              </div>
            </div>
            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
      </div>
      <div class="logoBlock">
        <img src="./images/every_log.png" alt="" height="52" />
        <p>限时限量 / 买一送一 / 下单立减</p>
      </div>
      <div class="spBlock">
        <i></i>
        <i class="right"></i>
        <div class="spBg">
          <img src="./images/typelogo.png" alt="" height="21" />
        </div>
      </div>
      <!-- goodsList start -->
      <div class="listBlock" bi-route="goods">
        <div bi-click="list" :data-index="index" :data-time="`${activeTimer.hpdTime}:00`" :data-gid="item.id || item.goodsId" class="listCent" v-for="(item, index) in goodsList" :key="item.id" >
          <a :href="`?r=/l/d&amp;id=${item.id || item.goodsId}`" target="_blank">
            <div class="img" v-marketgroup="item" >
              <img v-lazy="`${item.picUrl}_310x310.jpg`" alt="" />
            </div>
            <div class="content">
              <div :class="['title', item.shopType === 1 ? 'titleIcon' : '']">
                <p>{{ item.name }}</p>
              </div>
              <div class="coupon">
                <span :class="['quan', activeTimer.status == 2 ? 'quan1' : '']" v-if="item.couponAmount">
                  <i>{{ item.couponAmount }}元券</i>
                </span>
                <p class="tag" v-if="item.activityType === 2">聚划算</p>
              </div>
              <p :class="['des', activeTimer.status == 2 ? 'des1' : '']">
                <span v-if="item.yijuhua">{{ item.yijuhua }}</span>
              </p>
              <div class="num">
                已抢<i>{{ item.todaySellNum | salesNum }}</i>件
              </div>
              <div class="price">
                <p>￥ {{ item.price | salesNum }}</p>
              </div>
              <button class="noStart" v-if="activeTimer.status == 2" @click.stop="">
                未开始
              </button>
              <button class="qiang" v-else>马上抢</button>
            </div>
          </a>
        </div>
      </div>
      <!-- goodsList end -->
    </div>
  </div>
</template>
<script>
import { getInfoList } from "./services/index";
import Loading from "@/components/loading";
import { importCDN } from "@/utils/utils";
import { mapGetters } from "vuex";
export default {
  components: {
    Loading
  },
  computed: {
    ...mapGetters(["config"])
  },
  mounted() {
    if(this.config.site_name){
      document.title = `每日半价_${this.config.site_name}`;
    }
    this.getData();
    importCDN("https://public.ffquan.cn/lib/swiper/js/swiper.min.js").then(
      () => {
        this.mySwiper = new Swiper(".swiper-container", {
          // loop: true, // 循环模式选项
          direction: "horizontal",
          slidesPerView: 8,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
        this.mySwiper.update()
      }
    );
    document.addEventListener("scroll", this.scrollHeaderFixed);
    
  },
  destroyed () {
    this.mySwiper.destroy(false)  
    document.removeEventListener("scroll",this.scrollHeaderFixed,false);
  },
  data() {
    return {
      loading: true,
      timerList: [],
      goodsList: [],
      featerTimer: "",
      activeTimer: {},
      activeIndex: 0,
    };
  },
  methods: {
    getData: function (sessions) {
      this.loading = true
      let params = {
        sessions,
      };
      getInfoList(params).then((res) => {
        if (res && res.code !== 0) {
          return
        }
        this.loading = false
        this.timerList = res.data.sessionsList || [];
        this.goodsList = res.data.halfPriceInfo.list || [];
        var hour = new Date().getHours();
        if (!sessions) {
          this.activeTimer = this.timerList[0]
          for (let key in this.timerList) {
            if (this.timerList[key].hpdTime > hour) {
              this.activeIndex = key - 1;
              this.activeTimer = this.timerList[key]
              this.featerTimer = this.timerList[key].hpdTime;
              return key - 1;
            }
          }
        }
      });
    },
    hanldeTimerChange: function (item, index) {
      this.activeIndex = index;
      this.activeTimer = item;
      this.getData(item.hpdTime);
    },
    scrollHeaderFixed: function (){
        var scrollTop = document.documentElement.scrollTop;
        var dom = this.$refs.container;
        if (scrollTop >= 160) {
          dom.style.marginTop = "110px";
          document.getElementById("swiperNav").classList.add("fixed");
        } else {
          dom.style.marginTop = "0";
          document.getElementById("swiperNav").classList.remove("fixed");
        }
    },
  },
};
</script>

<style lang="less" scoped>
@import "https://public.ffquan.cn/lib/swiper/css/swiper.min.css";
.halfProceDaily {
  width: 1200px;
  margin: 30px auto 0;
  padding-top: 1px;
  .container {
    width: 100%;
    min-height: 550px;
    padding-bottom: 30px;
    position: relative;
    overflow: hidden;
    .topBg {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      height: 555px;
      background: linear-gradient(180deg, #ff5e09 0, #f6f6f6 100%);
      border-radius: 10px 10px 0 0;
    }
    .swiperNav {
      height: 100px;
      width: 1150px;
      margin: 0 auto;
      color: #fff;
      padding: 10px 0;
      box-sizing: border-box;
      position: relative;
      z-index: 1;
      .sceneNav {
        height: 100px;
        width: 1150px;
        margin: 0 auto;
        color: #fff;
        box-sizing: border-box;
        .swiper-container {
          margin: 0 auto;
          position: relative;
          overflow: visible;
          list-style: none;
          padding: 0;
          z-index: 1;
          .swiper-wrapper {
            z-index: 1;
            height: 100%;
            position: relative;
            .swiper-slide {
              width: 143.75px;
              font-size: 20px;
              font-weight: 700;
              color: #fff;
              text-align: center;
              padding: 0 20px;
              height: 60px;
              box-sizing: border-box;
              div {
                padding: 4px 10px;
                height: 52px;
                cursor: pointer;
                &:hover {
                  background: #fff;
                  border-radius: 5px;
                  color: #ff600d;
                  position: relative;
                }
              }
              p {
                display: block;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
          .swiper-button-prev {
            opacity: 0;
            display: block;
            background-size: 27px 44px;
            width: 40px;
            height: 40px;
            background: rgba(255, 255, 255, 0.3);
            border-radius: 0 100px 100px 0;
            outline: 0;
            position: absolute;
            left: -26px;
            top: 30px;
            font-size: 22px;
            z-index: 9;
            text-align: center;
            line-height: 40px;
            &::before {
              content: "";
              width: 0;
              height: 0;

              position: absolute;
              top: 50%;
              right: 0;
              left: 50%;

              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-right: 10px solid #fff;
              border-left: none;

              margin-top: -10px;
              margin-left: -8px;
              margin-right: 0;
            }
            &::after {
              content: "";
              width: 0;
              height: 0;
              position: absolute;
              top: 50%;
              right: 0;
              left: 50%;

              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-left: none;
              border-right: 10px solid #fff;
              border-right-color: rgba(254, 151, 57);
              border-width: 8px;

              margin-left: -6px;
              margin-top: -8px;
              margin-right: 0;
            }
            &:hover{
              opacity: 1;
            }
            &:hover .swiper-button-disabled {
                opacity: 0.35;
            }
          }
          .swiper-button-next {
            opacity: 0;
            display: block;
            background-size: 27px 44px;
            width: 40px;
            height: 40px;
            background: rgba(255, 255, 255, 0.3);
            border-radius: 100px 0 0 100px;
            outline: 0;
            position: absolute;
            right: -26px;
            top: 30px;
            font-size: 22px;
            z-index: 9;
            text-align: center;
            line-height: 40px;
            &::before {
              content: "";
              width: 0;
              height: 0;

              position: absolute;
              top: 50%;
              left: 50%;
              right: 0;

              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-left: 10px solid #fff;
              border-right: none;

              margin-top: -10px;
              margin-right: -8px;
              margin-left: 0;
            }
            &::after {
              content: "";
              width: 0;
              height: 0;
              position: absolute;
              top: 50%;
              left: 50%;
              right: 0;

              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-right: none;
              border-left: 10px solid #fff;
              border-left-color: rgba(254, 151, 57);
              border-width: 8px;

              margin-right: -6px;
              margin-top: -8px;
              margin-left: 0;
            }
            &:hover{
              opacity: 1;
            }
            &:hover .swiper-button-disabled {
                opacity: 0.35;
            }
          }

        }
        .act {
          background: #fff;
          border-radius: 5px;
          color: #ff600d;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            bottom: -8px;
            left: 50%;
            margin-left: -10px;
            border-top: 10px solid #fff;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
          }
        }
      }
    }
    .swiperNav:hover .sceneNav .swiper-container .swiper-button-prev,.swiper-button-next{
      opacity: 1;
      cursor: pointer;
    }
    .swiperNav:hover .sceneNav .swiper-container .swiper-button-disabled {
        opacity: 0.35;
    }

    .fixed {
      position: fixed;
      background: #ff620f;
      width: 100%;
      left: 0;
      top: 0;
      height: 86px;
      z-index: 9999;
    }
    .logoBlock {
      text-align: center;
      position: relative;
      p {
        position: absolute;
        right: 20px;
        color: #fff;
        font-size: 14px;
        top: 12px;
      }
    }
    .spBlock {
      width: 1160px;
      background: #fff;
      box-shadow: 0 2px 10px 0 rgba(178, 178, 178, 0.2);
      border-radius: 10px;
      margin: 12px auto 40px;
      position: relative;
      i {
        position: absolute;
        top: 10px;
        left: 50px;
        width: 14px;
        height: 14px;
        background: #fe7126;
        border-radius: 50%;
        &.right {
          right: 50px;
          left: auto;
        }
        &::before {
          content: "";
          position: absolute;
          width: 6px;
          height: 33px;
          background: linear-gradient(180deg, #fff2e7 0, #ef9769 100%);
          border-radius: 3px;
          bottom: 3px;
          left: 4px;
        }
      }
      .spBg {
        width: 1120px;
        height: 55px;
        line-height: 55px;
        margin: 0 auto;
        background: url(./images/sp_bg.png) top center no-repeat;
        background-size: 376px 38px;
        text-align: center;
        color: #fff;
      }
    }
    .listBlock {
      width: 1160px;
      margin: -55px auto 0;
      padding: 0;
      background: #fff;
      border-radius: 10px 10px 0 0;
      z-index: 99;
      min-height: 480px;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 50px;
      .listCent {
        width: 580px;
        height: 240px;
        border-bottom: 1px solid #eee;
        padding: 30px 0 30px 30px;
        box-sizing: border-box;
        a {
          text-decoration: none;
          display: block;
          width: 100%;
          height: 100%;
          border-right: 1px solid #eee;
          .img {
            width: 180px;
            height: 180px;
            border-radius: 10px;
            overflow: hidden;
            float: left;
            img {
              width: 180px;
              height: 180px;
              border-radius: 10px;
            }
          }
          &:hover {
            .content .title {
              p {
                color: #ff2b22;
              }
            }
            .img img {
              transform: scale(1.05, 1.05);
              transition-duration: 0.5s;
            }
          }
          .content {
            margin: 0 0 0 20px;
            width: 321px;
            float: left;
            .title {
              height: 18px;
              font-size: 16px;
              line-height: 16px;
              margin: 11px 0 8px;
              p {
                color: #333;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            .titleIcon {
              margin-left: 15px;
              position: relative;
              &::before {
                content: '';
                height: 15px;
                width: 15px;
                position: absolute;
                left: -20px;
                background: url('../berserk/images/tmall.png');
                background-size: 100% 100%;
              }
            }
            .coupon {
              height: 24px;
              color: #ff2b22;
              font-size: 13px;
              .tag {
                height: 22px;
                line-height: 24px;
                text-align: center;
                padding: 0 10px;
                background: #ffeceb;
                border-radius: 2px;
                border: 1px solid #ff2b22;
                float: left;
                margin-left: 10px;
              }
              .quan {
                height: 24px;
                line-height: 22px;
                background: url("./images/quan.png") center #fff8f8;
                background-size: 100% 100%;
                border-radius: 2px;
                padding: 2px 10px;
                position: relative;
                min-width: 57px;
                box-sizing: border-box;
                float: left;
                i {
                  font-style: normal;
                }
              }
              .quan1 {
                background: url("./images/quan1.png") center #fff8f8;
                color: #2AC064;
                background-size: 100% 100%;
              }
            }
            .des {
              color: #ff5e09;
              font-size: 16px;
              margin: 12px 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              height: 24px;
            }
            .des1 {
              color: #2AC064;
            }
            .num {
              height: 28px;
              margin: 12px 0 16px;
              font-size: 14px;
              color: #888;
              line-height: 28px;
              i {
                font-style: normal;
                padding: 0 2px;
              }
              text-align: right;
            }
            .price {
              height: 28px;
              margin: 12px 0 16px;
              p {
                color: #333;
                line-height: 28px;
                font-size: 20px;
              }
            }
            .qiang {
              width: 94px;
              height: 36px;
              border-radius: 20px;
              border: 1px solid #ff5e09;
              background: 0 0;
              font-size: 18px;
              font-family: MicrosoftYaHei;
              color: #ff5e09;
              line-height: 32px;
              position: relative;
              top: -50px;
              margin-right: -5px;
              float: right;
              &::after {
                content: "";
                position: absolute;
                right: -9px;
                top: 4px;
                width: 18px;
                height: 26px;
                background: url("./images/btnbg.png") center no-repeat;
                background-size: 100% 100%;
              }
              &:hover {
                background: #ff5e09;
                color: #fff;
              }
            }
            .noStart {
              width: 94px;
              height: 36px;
              border-radius: 20px;
              background: 0 0;
              font-size: 18px;
              font-family: MicrosoftYaHei;
              line-height: 32px;
              position: relative;
              top: -50px;
              margin-right: -5px;
              float: right;
              color: #ddd;
              border: 1px solid #ddd;
              // border: none;
            }
          }
        }
      }
    }
  }
}
</style>